// Styles
const styles = {
	dropzone: {
		border: '2px dashed #cccccc',
		borderRadius: '4px',
		padding: '20px',
		textAlign: 'center',
		cursor: 'pointer',
	},
	galleryList: {
		listStyle: 'none',
		padding: 0,
	},
	galleryItem: {
		cursor: 'pointer',
		border: '1px solid #ddd',
		padding: '10px',
		marginBottom: '10px',
		borderRadius: '4px',
	},
	galleryImage: {
		width: '320px',
		height: '200px',
		objectFit: 'cover',
	},
	sectionList: {
		listStyle: 'none',
		padding: 0,
		width: '100%',
		maxWidth: '300px',
	},
	sectionItem: {
		padding: '10px',
		marginBottom: '5px',
		backgroundColor: '#f0f0f0',
		border: '1px solid #ddd',
		borderRadius: '4px',
		cursor: 'move',
	},
	flexRow: {
		display: 'flex',
		alignItems: 'center',
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	buttonGroup: {
		display: 'flex',
		gap: '10px',
		marginBottom: '10px',
	},
	blogList: {
		listStyle: 'none',
		padding: 0,
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
	},
	blogItem: {
		border: '1px solid #ddd',
		borderRadius: '4px',
		padding: '10px',
		margin: '10px',
		cursor: 'pointer',
		width: '200px',
		textAlign: 'center',
	},
	blogImage: {
		width: '100%',
		height: 'auto',
		marginBottom: '10px',
	},
	section: {
		border: '1px solid #ddd',
		borderRadius: '4px',
		padding: '10px',
		margin: '10px 0',
	},
	blogCoverImage: {
		width: '200px',
		height: '150px',
		objectFit: 'cover',
	},
	jsonOutput: {
		marginTop: '20px',
		backgroundColor: '#f0f0f0',
		padding: '10px',
		borderRadius: '5px',
		overflow: 'auto',
	},
	thumbnailContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '10px',
		marginTop: '10px',
	},
	thumbnail: {
		width: '100px',
		height: '100px',
		objectFit: 'cover',
		border: '1px solid #ddd',
		borderRadius: '4px',
	},
	button: {
		margin: '10px 10px 10px 0',
		padding: '10px',
		backgroundColor: '#4CAF50',
		color: 'white',
		border: 'none',
		borderRadius: '5px',
		cursor: 'pointer',
	},
}

export default styles
