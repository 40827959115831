export const sortFiles = (files) => {
	return files.sort((a, b) =>
		a.name.localeCompare(b.name, undefined, {
			numeric: true,
			sensitivity: 'base',
		})
	)
}

export function processFilename(filename, width) {
	const lastDotIndex = filename.lastIndexOf('.')
	const name = filename.slice(0, lastDotIndex)
	const extension = filename.slice(lastDotIndex + 1)

	return `${name}-${width}.${extension}`
}

export const generateSlug = (title) => {
	return title
		.toLowerCase()
		.replace(/[^\w ]+/g, '')
		.replace(/ +/g, '-')
}
