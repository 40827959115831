// Constants
export const API_URL =
	process.env.REACT_APP_API_URL || 'http://localhost:3001/api'
export const CATEGORIES = [
	'weddings',
	'family',
	'portraits',
	'events',
	'collaborations',
	'travels',
]
