import axios from 'axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDropzone } from 'react-dropzone'
import { API_URL, CATEGORIES } from '../const'
import styles from '../styles'
import { generateSlug, processFilename, sortFiles } from '../utils'

// Custom hooks
const useGalleries = () => {
	const [clientGalleries, setClientGalleries] = useState([])
	const [websiteGalleries, setWebsiteGalleries] = useState([])

	useEffect(() => {
		const fetchGalleries = async () => {
			try {
				const [clientResponse, websiteResponse] = await Promise.all([
					axios.get(`${API_URL}/client`),
					axios.get(`${API_URL}/website`),
				])
				setClientGalleries(clientResponse.data)
				setWebsiteGalleries(websiteResponse.data)
			} catch (error) {
				console.error('Error fetching galleries:', error)
			}
		}
		fetchGalleries()
	}, [])

	return {
		clientGalleries,
		setClientGalleries,
		websiteGalleries,
		setWebsiteGalleries,
	}
}

const useUpload = (
	setGallery,
	clientGalleries,
	setClientGalleries,
	websiteGalleries,
	setWebsiteGalleries
) => {
	const [uploadStatus, setUploadStatus] = useState('')

	const sendToServer = useCallback(
		async (
			galleryFolder,
			galleryId,
			englishTitle,
			croatianTitle,
			categoryId
		) => {
			try {
				setUploadStatus('Sending data to server...')
				const { rootFolder, subfolders } = galleryFolder

				const isWebsiteGallery = subfolders.length === 0

				const rootFormData = new FormData()
				rootFormData.append('folderPath', `/${galleryId}`)
				rootFormData.append('date', new Date().toISOString())
				if (isWebsiteGallery) {
					rootFormData.append('categoryId', categoryId)
					rootFormData.append(
						'title',
						JSON.stringify({
							en: englishTitle,
							hr: croatianTitle,
						})
					)
					rootFormData.append(
						'slug',
						JSON.stringify({
							en: generateSlug(englishTitle),
							hr: generateSlug(croatianTitle),
						})
					)
				}
				sortFiles(rootFolder.files).forEach((file) =>
					rootFormData.append('files', file)
				)

				const rootResponse = await axios.post(
					`${API_URL}/${isWebsiteGallery ? 'website' : 'client'}`,
					rootFormData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				)

				let newGallery = rootResponse.data

				if (!isWebsiteGallery) {
					const subfoldersResponses = await Promise.all(
						subfolders.map(async (folder) => {
							const formData = new FormData()
							formData.append(
								'galleryId',
								rootResponse.data.galleryId
							)
							formData.append(
								'folderPath',
								folder.path.replace(
									rootFolder.path,
									`/${galleryId}`
								)
							)
							formData.append('date', new Date().toISOString())
							sortFiles(folder.files).forEach((file) =>
								formData.append('files', file)
							)

							return axios.post(
								`${API_URL}/client/sections`,
								formData,
								{
									headers: {
										'Content-Type': 'multipart/form-data',
									},
								}
							)
						})
					)

					newGallery = {
						...rootResponse.data,
						sections: subfoldersResponses.map((res) => res.data),
					}
				}

				setGallery(newGallery)
				setUploadStatus('All uploads successful!')

				if (isWebsiteGallery) {
					setWebsiteGalleries((prev) => [...prev, newGallery])
				} else {
					setClientGalleries((prev) => [...prev, newGallery])
				}
			} catch (error) {
				setUploadStatus('One or more uploads failed.')
				console.error('Error sending data to server:', error)
			}
		},
		[setGallery, setClientGalleries, setWebsiteGalleries]
	)

	return { uploadStatus, sendToServer }
}

const processGalleryFolder = (files) => {
	let rootFolder = { path: '', files: [] }
	const subfolders = {}

	files.forEach((file) => {
		const filePath = file.path.split('/')
		if (filePath.length === 3) {
			if (!rootFolder.path) rootFolder.path = `/${filePath[1]}`
			rootFolder.files.push(file)
		} else {
			const folderPath = filePath.slice(0, -1).join('/')
			if (!subfolders[folderPath])
				subfolders[folderPath] = { path: folderPath, files: [] }
			subfolders[folderPath].files.push(file)
		}
	})

	return { rootFolder, subfolders: Object.values(subfolders) }
}

// Components
const DropZone = ({ onDrop }) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	})

	return (
		<div {...getRootProps()} style={styles.dropzone}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<p>Drop the files here ...</p>
			) : (
				<p>Drag 'n' drop some files here, or click to select files</p>
			)}
		</div>
	)
}

const GalleryList = ({ galleries, onSelect, type }) => (
	<div className={`${type}`}>
		<h2>{type === 'client' ? 'Client' : 'Website'} Galleries</h2>
		<ul style={styles.galleryList}>
			{galleries.map((gallery) => (
				<li
					key={gallery.galleryId}
					onClick={() =>
						onSelect(gallery.galleryId, type === 'website')
					}
					style={styles.galleryItem}
				>
					<img
						src={`https://le-next.s3.eu-central-1.amazonaws.com/${processFilename(
							gallery.cover.src,
							1280
						)}`}
						alt={`Cover for ${gallery.galleryId}`}
						style={styles.galleryImage}
					/>
					<p>Gallery ID: {gallery.galleryId}</p>
				</li>
			))}
		</ul>
	</div>
)

const GalleryEditor = ({ gallery, setGallery, onUpdate, onDelete }) => {
	const isWebsiteGallery = !gallery.sections

	return (
		<div>
			<div style={styles.buttonGroup}>
				<button
					onClick={() =>
						navigator.clipboard.writeText(
							JSON.stringify(gallery, null, 2)
						)
					}
				>
					Copy
				</button>
				<button
					onClick={() => {
						const dataStr =
							'data:text/json;charset=utf-8,' +
							encodeURIComponent(JSON.stringify(gallery, null, 2))
						const downloadAnchorNode = document.createElement('a')
						downloadAnchorNode.setAttribute('href', dataStr)
						downloadAnchorNode.setAttribute(
							'download',
							'gallery_data.json'
						)
						document.body.appendChild(downloadAnchorNode)
						downloadAnchorNode.click()
						downloadAnchorNode.remove()
					}}
				>
					Download
				</button>
				<button onClick={onUpdate}>Update</button>
				<button onClick={onDelete}>Delete</button>
			</div>
			<div style={styles.flexRow}>
				<p>Date:</p>
				<DatePicker
					selected={new Date(gallery.date)}
					onChange={(date) =>
						setGallery({ ...gallery, date: date.toISOString() })
					}
					dateFormat='dd/MM/yyyy'
				/>
			</div>
			{isWebsiteGallery ? (
				<WebsiteGalleryEditor
					gallery={gallery}
					setGallery={setGallery}
				/>
			) : (
				<ClientGalleryEditor
					gallery={gallery}
					setGallery={setGallery}
				/>
			)}
			<pre>{JSON.stringify(gallery, null, 2)}</pre>
		</div>
	)
}

const WebsiteGalleryEditor = ({ gallery, setGallery }) => (
	<div style={styles.flexColumn}>
		<div style={styles.flexRow}>
			<p>Category:</p>
			<select
				value={gallery.categoryId}
				onChange={(e) =>
					setGallery({ ...gallery, categoryId: e.target.value })
				}
			>
				{CATEGORIES.map((category) => (
					<option key={category} value={category}>
						{category}
					</option>
				))}
			</select>
		</div>
		<TitleEditor
			title={gallery.title.en}
			slug={gallery.slug.en}
			onChange={(title) =>
				setGallery({
					...gallery,
					title: { ...gallery.title, en: title },
					slug: { ...gallery.slug, en: generateSlug(title) },
				})
			}
			lang='en'
		/>
		<TitleEditor
			title={gallery.title.hr}
			slug={gallery.slug.hr}
			onChange={(title) =>
				setGallery({
					...gallery,
					title: { ...gallery.title, hr: title },
					slug: { ...gallery.slug, hr: generateSlug(title) },
				})
			}
			lang='hr'
		/>
	</div>
)

const ClientGalleryEditor = ({ gallery, setGallery }) => {
	const dragItem = useRef()
	const dragOverItem = useRef()
	const [dragging, setDragging] = useState(false)

	const handleDrag = (e, position, dragType) => {
		if (dragType === 'start') {
			dragItem.current = position
			setDragging(true)
			e.target.style.opacity = '0.5'
		} else if (dragType === 'enter') {
			dragOverItem.current = position
			e.target.style.backgroundColor = '#e0e0e0'
		} else if (dragType === 'leave') {
			e.target.style.backgroundColor = '#f0f0f0'
		} else if (dragType === 'end') {
			const newSections = [...gallery.sections]
			const draggedItem = newSections[dragItem.current]
			newSections.splice(dragItem.current, 1)
			newSections.splice(dragOverItem.current, 0, draggedItem)
			setGallery({ ...gallery, sections: newSections })
			setDragging(false)
			e.target.style.opacity = '1'
			e.target.style.backgroundColor = '#f0f0f0'
		}
	}

	return (
		<ul style={styles.sectionList}>
			{gallery.sections.map((section, index) => (
				<li
					key={section.sectionId}
					style={{
						...styles.sectionItem,
						opacity:
							dragging && dragItem.current === index ? 0.5 : 1,
					}}
					draggable
					onDragStart={(e) => handleDrag(e, index, 'start')}
					onDragEnter={(e) => handleDrag(e, index, 'enter')}
					onDragLeave={(e) => handleDrag(e, index, 'leave')}
					onDragEnd={(e) => handleDrag(e, index, 'end')}
					onDragOver={(e) => e.preventDefault()}
				>
					{section.sectionId}
				</li>
			))}
		</ul>
	)
}

const TitleEditor = ({ title, slug, onChange, lang }) => (
	<div style={styles.flexRow}>
		<p>{lang === 'en' ? 'Title:' : 'Naslov:'}</p>
		<input
			type='text'
			value={title}
			onChange={(e) => onChange(e.target.value)}
			placeholder={`Enter ${
				lang === 'en' ? 'English' : 'Croatian'
			} title`}
		/>
		<p>URL Slug:</p>
		<input
			type='text'
			value={slug}
			readOnly
			placeholder='Generated URL slug'
		/>
	</div>
)

export const GalleryTab = () => {
	const [gallery, setGallery] = useState(null)
	const [droppedFiles, setDroppedFiles] = useState(null)
	const [englishTitle, setEnglishTitle] = useState('')
	const [croatianTitle, setCroatianTitle] = useState('')
	const [categoryId, setCategoryId] = useState('weddings')

	const {
		clientGalleries,
		setClientGalleries,
		websiteGalleries,
		setWebsiteGalleries,
	} = useGalleries()
	const { uploadStatus, sendToServer } = useUpload(
		setGallery,
		clientGalleries,
		setClientGalleries,
		websiteGalleries,
		setWebsiteGalleries
	)

	const handleDrop = useCallback((acceptedFiles) => {
		setDroppedFiles(acceptedFiles)

		// Extract the folder name from the first file's path
		if (acceptedFiles.length > 0) {
			const folderName = acceptedFiles[0].path.split('/')[1]
			setEnglishTitle(folderName)
			setCroatianTitle(folderName)
		}
	}, [])

	const handleUpload = () => {
		if (!droppedFiles || !englishTitle || !croatianTitle) {
			alert(
				'Please provide both English and Croatian titles before uploading.'
			)
			return
		}

		const galleryId = generateSlug(englishTitle)
		const galleryFolder = processGalleryFolder(droppedFiles)
		sendToServer(
			galleryFolder,
			galleryId,
			englishTitle,
			croatianTitle,
			categoryId
		)
	}

	const selectGallery = async (galleryId, isWebsiteGallery) => {
		try {
			let response
			if (isWebsiteGallery) {
				response = await axios.get(`${API_URL}/website`, {
					params: { galleryId },
				})
			} else {
				response = await axios.get(`${API_URL}/client`, {
					params: { galleryId },
				})
			}
			setGallery(response.data)
		} catch (error) {
			console.error('Error fetching gallery details:', error)
			alert('Failed to fetch gallery details')
		}
	}

	const updateGallery = async () => {
		if (!gallery) {
			alert('No gallery selected')
			return
		}

		const isWebsiteGallery = !gallery.sections

		try {
			const response = await axios.put(
				`${API_URL}/${isWebsiteGallery ? 'website' : 'client'}/${
					gallery.galleryId
				}`,
				{
					...(isWebsiteGallery
						? {
								categoryId: gallery.categoryId,
								title: gallery.title,
								slug: gallery.slug,
						  }
						: { sections: gallery.sections }),
					date: gallery.date,
				},
				{ headers: { 'Content-Type': 'application/json' } }
			)

			if (response.status === 200) {
				alert('Gallery updated successfully!')
				setGallery(response.data)
			}
		} catch (error) {
			console.error('Error updating gallery:', error)
			alert('Failed to update gallery')
		}
	}

	const deleteGallery = async () => {
		if (!gallery) {
			alert('No gallery selected')
			return
		}

		const isWebsiteGallery = !gallery.sections

		try {
			const response = await axios.delete(
				`${API_URL}/${
					isWebsiteGallery ? 'website' : 'client'
				}?galleryId=${gallery.galleryId}`
			)
			if (response.status === 204) {
				alert('Gallery deleted successfully')
				setGallery(null)
				if (isWebsiteGallery) {
					setWebsiteGalleries(
						websiteGalleries.filter(
							(g) => g.galleryId !== gallery.galleryId
						)
					)
				} else {
					setClientGalleries(
						clientGalleries.filter(
							(g) => g.galleryId !== gallery.galleryId
						)
					)
				}
			}
		} catch (error) {
			console.error('Error deleting gallery:', error)
			alert('Failed to delete gallery')
		}
	}

	const resetGalleryTable = async () => {
		try {
			console.log('Sending request to reset Gallery tables...')
			const [clientResponse, websiteResponse] = await Promise.all([
				axios.delete(`${API_URL}/client`),
				axios.delete(`${API_URL}/website`),
			])
			console.log('Received responses:', clientResponse, websiteResponse)
			if (
				clientResponse.status === 200 &&
				websiteResponse.status === 200
			) {
				alert('Gallery tables reset successfully')
				setGallery(null)
				setClientGalleries([])
				setWebsiteGalleries([])
			}
		} catch (error) {
			console.error('Error resetting Gallery tables:', error)
			console.error('Error response:', error.response)
			alert(
				`Failed to reset Gallery tables: ${
					error.response?.data?.error || error.message
				}`
			)
		}
	}

	return (
		<div className='App'>
			{!droppedFiles ? (
				<DropZone onDrop={handleDrop} />
			) : (
				<div>
					<h2>Prepare Upload</h2>
					<input
						type='text'
						value={englishTitle}
						onChange={(e) => setEnglishTitle(e.target.value)}
						placeholder='Enter English title'
					/>
					<p>English Slug: {generateSlug(englishTitle)}</p>
					<input
						type='text'
						value={croatianTitle}
						onChange={(e) => setCroatianTitle(e.target.value)}
						placeholder='Enter Croatian title'
					/>
					<p>Croatian Slug: {generateSlug(croatianTitle)}</p>
					<button onClick={handleUpload}>Upload</button>
					<button onClick={() => setDroppedFiles(null)}>
						Cancel
					</button>
				</div>
			)}

			<button onClick={resetGalleryTable}>Reset Galleries</button>

			{uploadStatus && <p>{uploadStatus}</p>}
			<div className='gallery-lists'>
				<GalleryList
					galleries={clientGalleries}
					onSelect={selectGallery}
					type='client'
				/>
				<GalleryList
					galleries={websiteGalleries}
					onSelect={selectGallery}
					type='website'
				/>
			</div>
			{gallery && (
				<GalleryEditor
					gallery={gallery}
					setGallery={setGallery}
					onUpdate={updateGallery}
					onDelete={deleteGallery}
				/>
			)}
		</div>
	)
}
