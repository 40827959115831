import axios from 'axios'
import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { API_URL } from '../const'
import styles from '../styles'
import { processFilename } from '../utils'

export const ImageUploadTab = () => {
	const [uploadedImages, setUploadedImages] = useState([])
	const [folderPath, setFolderPath] = useState('')
	const [uploadStatus, setUploadStatus] = useState('')
	const jsonRef = useRef(null)

	const onDrop = useCallback(
		async (acceptedFiles) => {
			if (!folderPath) {
				alert('Please enter a folder path before uploading.')
				return
			}
			setUploadStatus('Uploading...')
			const formData = new FormData()
			acceptedFiles.forEach((file) => {
				formData.append('files', file)
			})
			formData.append('folderPath', folderPath)
			try {
				const response = await axios.post(
					`${API_URL}/images`,
					formData,
					{
						headers: { 'Content-Type': 'multipart/form-data' },
					}
				)
				setUploadedImages(response.data)
				setUploadStatus('Upload successful!')
			} catch (error) {
				console.error('Error uploading images:', error)
				setUploadStatus('Upload failed. Please try again.')
			}
		},
		[folderPath]
	)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	})

	const copyToClipboard = () => {
		if (jsonRef.current) {
			navigator.clipboard.writeText(jsonRef.current.textContent)
			alert('JSON copied to clipboard!')
		}
	}

	const downloadJSON = () => {
		const dataStr =
			'data:text/json;charset=utf-8,' +
			encodeURIComponent(JSON.stringify(uploadedImages, null, 2))
		const downloadAnchorNode = document.createElement('a')
		downloadAnchorNode.setAttribute('href', dataStr)
		downloadAnchorNode.setAttribute('download', 'uploaded_images.json')
		document.body.appendChild(downloadAnchorNode)
		downloadAnchorNode.click()
		downloadAnchorNode.remove()
	}

	return (
		<div>
			<h2>Image Upload</h2>
			<input
				type='text'
				value={folderPath}
				onChange={(e) => setFolderPath(e.target.value)}
				placeholder='Enter folder path'
				style={styles.input}
			/>
			<div {...getRootProps()} style={styles.dropzone}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<p>Drop the images here ...</p>
				) : (
					<p>Drag 'n' drop images here, or click to select files</p>
				)}
			</div>
			{uploadStatus && <p>{uploadStatus}</p>}
			{uploadedImages.length > 0 && (
				<div>
					<h3>Uploaded Images:</h3>
					<ul style={styles.galleryList}>
						{uploadedImages.map((image, index) => (
							<li key={index} style={styles.imageItem}>
								<img
									src={`https://le-next.s3.eu-central-1.amazonaws.com/${processFilename(
										image.src,
										1280
									)}`}
									alt='img'
									style={styles.galleryImage}
								/>
								<p>Source: {image.src}</p>
							</li>
						))}
					</ul>
					<h3>JSON Output:</h3>
					<pre ref={jsonRef} style={styles.jsonOutput}>
						{JSON.stringify(uploadedImages, null, 2)}
					</pre>
					<button onClick={copyToClipboard} style={styles.button}>
						Copy JSON
					</button>
					<button onClick={downloadJSON} style={styles.button}>
						Download JSON
					</button>
				</div>
			)}
		</div>
	)
}
