import { useState } from 'react'
import { GalleryTab } from './tabs/Galleries'
import { BlogTab } from './tabs/Blogs'
import { ImageUploadTab } from './tabs/Images'

function App() {
	const [activeTab, setActiveTab] = useState('galleries')

	return (
		<div className='App'>
			<div>
				<button onClick={() => setActiveTab('galleries')}>
					Galleries
				</button>
				<button onClick={() => setActiveTab('blogs')}>Blogs</button>
				<button onClick={() => setActiveTab('images')}>Images</button>
			</div>
			{activeTab === 'galleries' && <GalleryTab />}
			{activeTab === 'blogs' && <BlogTab />}
			{activeTab === 'images' && <ImageUploadTab />}
		</div>
	)
}

export default App
