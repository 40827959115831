import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDropzone } from 'react-dropzone'
import { API_URL } from '../const'
import styles from '../styles'
import { generateSlug, processFilename } from '../utils'

const SectionDropzone = ({ onDrop }) => {
	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	return (
		<div {...getRootProps()} style={styles.dropzone}>
			<input {...getInputProps()} />
			<p>
				Drag 'n' drop section images here, or click to select files (max
				10)
			</p>
		</div>
	)
}

const BlogEditor = ({ blog, setBlog, onSubmit, onUpdate, onDelete }) => {
	const [sections, setSections] = useState(blog?.sections || [])

	useEffect(() => {
		if (blog) {
			setSections(blog.sections || [])
		}
	}, [blog])

	const addSection = () => {
		setSections([
			...sections,
			{ title: { en: '', hr: '' }, text: { en: '', hr: '' }, images: [] },
		])
	}

	const removeSection = (index) => {
		setSections(sections.filter((_, i) => i !== index))
	}

	const updateSection = (index, field, lang, value) => {
		const newSections = [...sections]
		newSections[index][field][lang] = value
		setSections(newSections)
	}

	const onCoverDrop = useCallback(
		(acceptedFiles) => {
			setBlog({
				...blog,
				cover: acceptedFiles[0],
				mobileCover: acceptedFiles[1] || null,
			})
		},
		[blog, setBlog]
	)

	const onSectionDrop = useCallback(
		(acceptedFiles, index) => {
			const newSections = [...sections]
			const newImages = [
				...newSections[index].images,
				...acceptedFiles.slice(
					0,
					10 - newSections[index].images.length
				),
			]
			newSections[index].images = newImages
			setSections(newSections)
		},
		[sections]
	)

	const {
		getRootProps: getCoverRootProps,
		getInputProps: getCoverInputProps,
	} = useDropzone({ onDrop: onCoverDrop, multiple: true, maxFiles: 2 })

	const handleSubmit = () => {
		const updatedBlog = {
			...blog,
			sections: sections,
		}
		onSubmit(updatedBlog)
	}

	return (
		<div>
			<input
				type='text'
				value={blog?.title?.en || ''}
				onChange={(e) =>
					setBlog({
						...blog,
						title: { ...blog.title, en: e.target.value },
					})
				}
				placeholder='English title'
			/>
			<input
				type='text'
				value={blog?.title?.hr || ''}
				onChange={(e) =>
					setBlog({
						...blog,
						title: { ...blog.title, hr: e.target.value },
					})
				}
				placeholder='Hrvatski naslov'
			/>
			<DatePicker
				selected={blog?.date ? new Date(blog.date) : new Date()}
				onChange={(date) =>
					setBlog({ ...blog, date: date.toISOString() })
				}
			/>
			<div {...getCoverRootProps()} style={styles.dropzone}>
				<input {...getCoverInputProps()} />
				<p>
					Drag 'n' drop cover images here, or click to select files
					(max 2: desktop and mobile)
				</p>
			</div>
			{blog?.cover && <p>Desktop cover image: {blog.cover.name}</p>}
			{blog?.mobileCover && (
				<p>Mobile cover image: {blog.mobileCover.name}</p>
			)}
			{sections.map((section, index) => (
				<div key={index}>
					<input
						type='text'
						value={section.title?.en || ''}
						onChange={(e) =>
							updateSection(index, 'title', 'en', e.target.value)
						}
						placeholder='English section title'
					/>
					<input
						type='text'
						value={section.title?.hr || ''}
						onChange={(e) =>
							updateSection(index, 'title', 'hr', e.target.value)
						}
						placeholder='Hrvatski naslov sekcije'
					/>
					<textarea
						value={section.text?.en || ''}
						onChange={(e) =>
							updateSection(index, 'text', 'en', e.target.value)
						}
						placeholder='Engleski tekst sekcije'
					/>
					<textarea
						value={section.text?.hr || ''}
						onChange={(e) =>
							updateSection(index, 'text', 'hr', e.target.value)
						}
						placeholder='Hrvatski tekst sekcije'
					/>
					<SectionDropzone
						onDrop={(files) => onSectionDrop(files, index)}
					/>
					<p>Section images: {section.images.length}</p>
					<button onClick={() => removeSection(index)}>
						Remove Section
					</button>
				</div>
			))}
			<button onClick={addSection}>Add Section</button>
			<button onClick={handleSubmit}>Submit Blog</button>
			{blog?.blogId && (
				<>
					<button onClick={() => onUpdate(blog)}>Update Blog</button>
					<button onClick={() => onDelete(blog.blogId)}>
						Delete Blog
					</button>
				</>
			)}
		</div>
	)
}

export const BlogTab = () => {
	const [blogs, setBlogs] = useState([])
	const [selectedBlog, setSelectedBlog] = useState({
		title: { en: '', hr: '' },
		date: new Date().toISOString(),
		sections: [],
	})
	const [jsonOutput, setJsonOutput] = useState('')

	useEffect(() => {
		const fetchBlogs = async () => {
			try {
				const response = await axios.get(`${API_URL}/blog`)
				setBlogs(response.data)
			} catch (error) {
				console.error('Error fetching blogs:', error)
			}
		}
		fetchBlogs()
	}, [])

	const handleSubmit = async (blog) => {
		try {
			const formData = new FormData()
			formData.append('blogId', generateSlug(blog.title.en))

			const date = blog.date
				? new Date(blog.date).toISOString()
				: new Date().toISOString()
			formData.append('date', date)

			formData.append('title', JSON.stringify(blog.title))
			formData.append(
				'slug',
				JSON.stringify({
					en: generateSlug(blog.title.en),
					hr: generateSlug(blog.title.hr),
				})
			)
			formData.append('sections', JSON.stringify(blog.sections))

			if (blog.cover) {
				formData.append('cover', blog.cover)
			}
			if (blog.mobileCover) {
				formData.append('cover', blog.mobileCover)
			}

			if (blog.sections) {
				blog.sections.forEach((section, index) => {
					if (section.images) {
						section.images.forEach((image, imageIndex) => {
							if (image instanceof File) {
								formData.append('sectionImages', image)
							}
						})
					}
				})
			}

			const response = await axios.post(`${API_URL}/blog`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			setBlogs([...blogs, response.data])
			setSelectedBlog(null)
			setJsonOutput(JSON.stringify(response.data, null, 2))
		} catch (error) {
			console.error('Error creating blog:', error)
		}
	}

	const handleUpdate = async (blog) => {
		try {
			const date = blog.date
				? new Date(blog.date).toISOString()
				: new Date().toISOString()

			const response = await axios.put(`${API_URL}/blog/${blog.blogId}`, {
				date: date,
				title: blog.title,
				slug: {
					en: generateSlug(blog.title.en),
					hr: generateSlug(blog.title.hr),
				},
				sections: blog.sections,
			})
			setBlogs(
				blogs.map((b) => (b.blogId === blog.blogId ? response.data : b))
			)
			setSelectedBlog(null)
			setJsonOutput(JSON.stringify(response.data, null, 2))
		} catch (error) {
			console.error('Error updating blog:', error)
		}
	}

	const handleDelete = async (blogId) => {
		try {
			await axios.delete(`${API_URL}/blog?blogId=${blogId}`)
			setBlogs(blogs.filter((b) => b.blogId !== blogId))
			setSelectedBlog(null)
			setJsonOutput('')
		} catch (error) {
			console.error('Error deleting blog:', error)
		}
	}

	const handleDeleteAllBlogs = async () => {
		try {
			await axios.delete(`${API_URL}/blog`)
			setBlogs([])
			setSelectedBlog(null)
			setJsonOutput('')
			alert('All blogs have been deleted successfully.')
		} catch (error) {
			console.error('Error deleting all blogs:', error)
			alert('Failed to delete all blogs. Please try again.')
		}
	}

	const handleSelectBlog = async (blog) => {
		try {
			const response = await axios.get(`${API_URL}/blog`, {
				params: { blogId: blog.blogId },
			})
			setSelectedBlog(response.data)
			setJsonOutput(JSON.stringify(response.data, null, 2))
		} catch (error) {
			console.error('Error fetching blog details:', error)
		}
	}

	return (
		<div>
			<h2>Blogs</h2>
			<button
				onClick={handleDeleteAllBlogs}
				style={{
					backgroundColor: 'red',
					color: 'white',
					marginBottom: '10px',
				}}
			>
				Delete All Blogs
			</button>
			<ul style={styles.blogList}>
				{blogs.map((blog) => (
					<li
						key={blog.blogId}
						onClick={() => handleSelectBlog(blog)}
						style={styles.blogItem}
					>
						<img
							src={`https://le-next.s3.eu-central-1.amazonaws.com/${processFilename(
								blog.cover.src,
								1280
							)}`}
							alt={`Cover for ${blog.title.en}`}
							style={styles.blogCoverImage}
						/>
						<p>{blog.title.en}</p>
					</li>
				))}
			</ul>
			<BlogEditor
				blog={selectedBlog}
				setBlog={setSelectedBlog}
				onSubmit={handleSubmit}
				onUpdate={handleUpdate}
				onDelete={handleDelete}
			/>
			{jsonOutput && (
				<div style={styles.jsonOutput}>
					<h3>JSON Output</h3>
					<pre>{jsonOutput}</pre>
				</div>
			)}
		</div>
	)
}
